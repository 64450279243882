import { makeAutoObservable } from 'mobx';
import {
  doLoadMyFacilities,
  getReservationReview,
  loadByCode,
  loadData,
} from '@/services/myReservationService';
import dayjs, { Dayjs } from 'dayjs';
import { fetchTemplate } from '@/utils/isServer';
import {
  IMember,
  IMyFacility,
  IReservationCodeResponse,
  IReservationReviewResponse,
} from '@/types';

class MyReservationStore {
  currentData = [];
  pastData = [];
  review: IReservationReviewResponse = null;
  today: Dayjs = dayjs();
  accountInfo: IMember;
  hasMemberTokenSubscription = false;
  rebookReservations: [
    {
      reservation_code: string;
      reservation_id: string;
      facility_name: string;
      facility_url_code: string;
      keep_amount: number;
    }
  ];
  myFacilities: IMyFacility[] = null;
  environment: number;
  reservationCode: IReservationCodeResponse;
  loadingConfirmed = false;
  myReservationReviewModal = {
    isVisible: false,
    data: {},
    reviewed: null,
  };

  constructor() {
    makeAutoObservable(this);
  }

  async loadTabData() {
    try {
      const data = await loadData();
      this.setCurrentData(data);
      this.setPastData(data);
    } catch (error) {
      console.log(error);
    }
  }

  clearReview() {
    this.review = null;
  }

  async loadMyFacilities() {
    if (!this.myFacilities) {
      const resp = await doLoadMyFacilities();
      console.log(resp);
      if (resp && resp.status == 'OK' && resp.result) {
        this.myFacilities = resp.result;
        this.environment = resp.environment;
      } else {
        this.myFacilities = null;
      }
    }
    return this.myFacilities;
  }
  clearMyFacilities() {
    this.myFacilities = null;
  }
  async loadReview(id: number) {
    try {
      this.review = await getReservationReview(id);
    } catch (error) {
      console.log(error);
    }
  }
  getReviewUser() {
    let rname = this.review?.result?.reservation_first_name;
    if (this.review?.result?.reservation_last_name) {
      rname +=
        ' ' + this.review?.result?.reservation_last_name?.charAt(0) + '.';
    }
    return rname;
  }
  setCurrentData(data) {
    this.currentData = data?.result
      ?.filter((item) => {
        const checking = item.dayjs_reservation_dt_checkin.add(1, 'day');
        return this.today.isSame(checking) || this.today.isBefore(checking);
      })
      .sort((a, b) =>
        a.dayjs_reservation_dt_checkin.isBefore(b.dayjs_reservation_dt_checkin)
          ? 1
          : 0
      );
  }

  setPastData(data) {
    this.pastData = data?.result
      ?.filter((item) => {
        const checking = item.dayjs_reservation_dt_checkin.add(1, 'day');
        return this.today.isAfter(checking);
      })
      .sort((a, b) =>
        a.dayjs_reservation_dt_checkin.isBefore(b.dayjs_reservation_dt_checkin)
          ? 1
          : 0
      );
  }

  async loadAccountInfo(member_id: string) {
    try {
      const response = await fetch(`/api/Member/${member_id}`, {
        ...fetchTemplate,
      });
      const { Member, hasMemberTokenSubscription, rebookReservations } =
        await response.json();
      this.setAccountInfo(Member);
      this.hasMemberTokenSubscription = hasMemberTokenSubscription;
      this.rebookReservations = rebookReservations;
    } catch (error) {
      console.log(error);
    }
  }

  setAccountInfo(data) {
    this.accountInfo = data;
  }

  async loadConfirmedByCode(reservation_code: string) {
    this.setLoadingConfirmed(true);
    let rc = reservation_code;
    if (rc.startsWith('allow_cancellation_')) {
      rc = rc.replace('allow_cancellation_', '');
    }
    const result = await loadByCode(rc);
    this.setReservationCode(result);
    this.setLoadingConfirmed(false);
  }

  setReservationCode(data) {
    this.reservationCode = data;
  }

  setLoadingConfirmed(value) {
    this.loadingConfirmed = value;
  }

  setMyReservationReviewModal(visible: boolean, data: any, reviewed: number) {
    this.myReservationReviewModal.isVisible = visible;
    this.myReservationReviewModal.data = data;
    this.myReservationReviewModal.reviewed = reviewed;
  }
}

export default MyReservationStore;
